import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import ChatGPT from './components/ChatGPT';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/chat" element={<ChatGPT />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;


/* import React, { useState } from 'react';
import axios from 'axios';

function App() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email })
            .then(response => setCustomer(response.data))
            .catch(error => console.error('There was an error!', error));
    };

    return (
        <div className="App">
            <header className="App-header">
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                    />
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    <button type="submit">Create Customer</button>
                </form>
                {customer && (
                    <div>
                        <h2>Customer Created:</h2>
                        <p>ID: {customer.id}</p>
                        <p>Name: {customer.name}</p>
                        <p>Email: {customer.email}</p>
                    </div>
                )}
            </header>
        </div>
    );
}

export default App;
*/
