import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { RefreshCw, Zap } from 'lucide-react';
import './ChatGPT.css';
import zendeskIcon from '../assets/Zendesk.png';
import stripeIcon from '../assets/Stripe.png';
import ChatSidebar from './ChatSidebar';
import TicketTable from './TicketTable';

const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isBoosterRunning, setIsBoosterRunning] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [emailContent, setEmailContent] = useState('');
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            setTableData(prevData => 
                prevData.map(ticket => 
                    ticket.id === ticketId 
                        ? { ...ticket, conversations: response.data.conversations }
                        : ticket
                )
            );
        } catch (error) {
            console.error('Error fetching ticket details:', error);
        }
    };

    const fetchTickets = () => {
        setIsRefreshing(true);
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => ({
                        id: ticket.id,
                        avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                        name: ticket.requester.name || ticket.requester.email || 'Unknown',
                        ticketDescription: ticket.subject || 'No subject',
                        sentimentalAnalysis: calculateSentiment(ticket.description),
                        updatedAt: new Date(ticket.updated_at),
                        status: ticket.status,
                        tags: [...(ticket.tags || [])],
                        rawData: ticket
                    }));
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    const handleTicketClick = async (ticketId) => {
        setExpandedRows(prev => ({
            ...prev,
            [ticketId]: !prev[ticketId]
        }));

        if (!expandedRows[ticketId]) {
            await fetchTicketDetails(ticketId);
        }
    };

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'open':
                return 'statusOpen';
            case 'closed':
                return 'statusClosed';
            case 'solved':
                return 'statusSolved';
            default:
                return '';
        }
    };

    const renderTags = useCallback((tags) => {
        if (!tags || tags.length === 0) {
            return <span className="no-tags">No tags</span>;
        }
        return (
            <div className="tag-container">
                {tags.map((tag, index) => (
                    <span key={`${tag}-${index}`} className="tag">{tag}</span>
                ))}
            </div>
        );
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3;
        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];
        let score = 3;
        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });
        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });
        return Math.max(1, Math.min(5, Math.round(score)));
    };

    const getAvatarColor = (initial) => {
        if (!initial) return '#000000';
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const handleBooster = () => {
        setIsBoosterRunning(true);
        axios.post('/api/chat/', { message: 'solve all the tickets', thread_id: null }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log('Booster response:', response.data);
                fetchTickets();
            })
            .catch(error => {
                console.error('Booster error:', error);
                if (error.response) {
                    alert(`Booster Error: ${error.response.data.error}`);
                } else {
                    alert(`Booster Error: ${error.message}`);
                }
            })
            .finally(() => {
                setIsBoosterRunning(false);
            });
    };

    const handleSort = (column, direction) => {
        setSortColumn(column);
        setSortDirection(direction);
        const sortedData = [...tableData].sort((a, b) => {
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setTableData(sortedData);
    };

    return (
        <div className="chat-container">
            <ChatSidebar />
            <div className="main-content">
                <div className="table-header">
                    <div className="header-left">
                        <span className="logo">N</span>
                        <h2>
                            <svg xmlns="http://www.w3.org/2000/svg" className="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                            </svg>
                            Support Tickets
                        </h2>
                    </div>
                    <div className="button-group">
                        <button
                            className="header-button refresh-button"
                            onClick={fetchTickets}
                            disabled={isRefreshing}
                        >
                            <RefreshCw size={20} className={isRefreshing ? 'spin' : ''} />
                            {isRefreshing ? 'Refreshing...' : 'Refresh'}
                        </button>
                        <button
                            className="header-button booster-button"
                            onClick={handleBooster}
                            disabled={isBoosterRunning}
                        >
                            <Zap size={20} className={isBoosterRunning ? 'pulse' : ''} />
                            {isBoosterRunning ? 'Boosting...' : 'Booster'}
                        </button>
                    </div>
                </div>
                <TicketTable 
                    currentItems={currentItems}
                    handleTicketClick={handleTicketClick}
                    expandedRows={expandedRows}
                    renderTags={renderTags}
                    getStatusClass={getStatusClass}
                    getAvatarColor={getAvatarColor}
                    formatDate={formatDate}
                    emailContent={emailContent}
                    setEmailContent={setEmailContent}
                    onSort={handleSort}
                    currentPage={currentPage}
                    totalPages={Math.ceil(tableData.length / itemsPerPage)}
                    onPageChange={setCurrentPage}
                    fetchTicketDetails={fetchTicketDetails}
                />
                <div className="table-footer">
                    <span>Rows per page: {itemsPerPage} ▼</span>
                    <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                    <div className="pagination-controls">
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatGPT;

/* Aug 21 Save
import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star, RefreshCw, Zap, ArrowLeft } from 'lucide-react';
import './ChatGPT.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import zendeskIcon from '../assets/Zendesk.png';
import stripeIcon from '../assets/Stripe.png';
import { FaUser as UserIcon, FaHeadset as SupportIcon } from 'react-icons/fa';



const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isBoosterRunning, setIsBoosterRunning] = useState(false);
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("Current Items:", currentItems);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);

    const [expandedRows, setExpandedRows] = useState({});

    const formatDate = (timestamp) => {
        return new Date(timestamp).toLocaleString();
      };

    const [emailContent, setEmailContent] = useState('');

    const [selectedTicket, setSelectedTicket] = useState(null);

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            setTableData(prevData => 
                prevData.map(ticket => 
                    ticket.id === ticketId 
                        ? { ...ticket, conversations: response.data.conversations }
                        : ticket
                )
            );
        } catch (error) {
            console.error('Error fetching ticket details:', error);
        }
    };

    const fetchTickets = () => {
        setIsRefreshing(true);
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        const formattedTicket = {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            tags: [...(ticket.tags || [])],
                            rawData: ticket
                        };
                        console.log(`Formatted ticket ${ticket.id}:`, formattedTicket);
                        return formattedTicket;
                    });
                    console.log("Formatted ticket data:", formattedData);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        console.log("Table data updated:", tableData);
        tableData.forEach(ticket => {
            console.log(`Ticket ${ticket.id} tags:`, ticket.tags);
        });
    }, [tableData]);

    const handleTicketClick = async (ticketId) => {
        setExpandedRows(prev => ({
            ...prev,
            [ticketId]: !prev[ticketId]
        }));

        if (!expandedRows[ticketId]) {
            const ticketDetails = await fetchTicketDetails(ticketId);
            if (ticketDetails) {
                setTableData(prevData =>
                    prevData.map(ticket =>
                        ticket.id === ticketId
                            ? { ...ticket, conversations: ticketDetails.conversations }
                            : ticket
                    )
                );
            }
        }
    };



    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
          case 'open':
            return 'status-open';
          case 'closed':
            return 'status-closed';
          case 'solved':
            return 'status-solved';
          default:
            return '';
        }
      };


    const renderTags = React.useCallback((tags) => {
        console.log("Rendering tags for ticket:", tags);
        if (!tags || tags.length === 0) {
            console.log("No tags to render");
            return <span className="no-tags">No tags</span>;
        }
        return (
            <div className="tag-container">
                {tags.map((tag, index) => {
                    console.log(`Rendering tag ${index}:`, tag);
                    return <span key={`${tag}-${index}`} className="tag">{tag}</span>;
                })}
            </div>
        );
    }, []);

    const TicketDetailView = ({ ticket, onBack }) => (
        <div className="ticket-detail">
            <div className="table-header">
                <h2>Ticket Details</h2>
                <div className="button-group">
                    <button className="back-button" onClick={onBack}>
                        <ArrowLeft size={20} />
                        Back to Tickets
                    </button>
                </div>
            </div>
            {ticket ? (
                <>
                    <div className="ticket-subject">{ticket.subject}</div>
                    <div className="ticket-conversations">
                        {ticket.conversations && ticket.conversations.map((message, index) => (
                            <div key={index} className={`message ${message.sender}`}>
                                <p>{message.content}</p>
                                <div className="message-time">
                                    {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p>Loading ticket details...</p>
            )}
        </div>
    );


    useEffect(() => {
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        return {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            rawData: ticket
                        };
                    });
                    formattedData.sort((a, b) => b.updatedAt - a.updatedAt);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            });
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3; // Default to neutral if no description

        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];

        let score = 3; // Neutral starting point

        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });

        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });

        return Math.max(1, Math.min(5, Math.round(score))); // Ensure score is between 1 and 5
    };


    const getAvatarColor = (initial) => {
        if (!initial) return '#000000'; // Default color if no initial is provided
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const renderRow = useCallback((row) => {
        return (
            <React.Fragment key={row.id}>
                <tr onClick={() => handleTicketClick(row.id)}>
                    {}
                </tr>
                {expandedRows[row.id] && (
                    <tr className="expanded-row">
                        <td colSpan="9">
                            <div className="ticket-details">
                                <h3>{row.ticketDescription}</h3>
                                <div className="conversation-container">
                                    {row.conversations && row.conversations.map((comment, index) => (
                                        <div key={index} className={`comment ${comment.sender}`}>
                                            <p>{comment.content}</p>
                                            <span className="comment-time">{new Date(comment.timestamp).toLocaleString()}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="intelligent-ai-email">
                                    <h4>Intelligent AI Email</h4>
                                    <ReactQuill
                                        theme="snow"
                                        value={emailContent}
                                        onChange={setEmailContent}
                                        modules={{
                                            toolbar: [
                                                ['bold', 'italic', 'underline', 'strike'],
                                                ['blockquote', 'code-block'],
                                                [{ 'header': 1 }, { 'header': 2 }],
                                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                [{ 'direction': 'rtl' }],
                                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                [{ 'color': [] }, { 'background': [] }],
                                                [{ 'font': [] }],
                                                [{ 'align': [] }],
                                                ['clean']
                                            ],
                                        }}
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }, [handleTicketClick, getAvatarColor, renderStars, renderTags, expandedRows, emailContent, setEmailContent]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    const handleBooster = () => {
        setIsBoosterRunning(true);
        axios.post('/api/chat/', { message: 'solve all the tickets', thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log('Booster response:', response.data);
                const newMessage = {
                    user: 'Booster triggered',
                    ai: response.data.message
                };
                setChatHistory(prevHistory => [...prevHistory, newMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }

                // Refresh tickets after booster completes
                fetchTickets();
            })
            .catch(error => {
                console.error('Booster error:', error);
                if (error.response) {
                    console.error('Error response data:', error.response.data);
                    console.error('Error response status:', error.response.status);
                    console.error('Error response headers:', error.response.headers);
                    alert(`Booster Error: ${error.response.data.error}`);
                } else {
                    alert(`Booster Error: ${error.message}`);
                }
            })
            .finally(() => {
                setIsBoosterRunning(false);
            });
    };

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>NAACK AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                <div className="table-header">
                    <h2>
                        Support Tickets
                        <img src={zendeskIcon} alt="Zendesk" className="header-icon zendesk-icon" />
                        <img src={stripeIcon} alt="Stripe" className="header-icon stripe-icon" />
                    </h2>
                    <div className="button-group">
                        <button
                            className="refresh-button"
                            onClick={fetchTickets}
                            disabled={isRefreshing}
                        >
                            <RefreshCw size={20} className={isRefreshing ? 'spin' : ''} />
                            {isRefreshing ? 'Refreshing...' : 'Refresh'}
                        </button>
                        <button
                            className="booster-button"
                            onClick={handleBooster}
                            disabled={isBoosterRunning}
                        >
                            <Zap size={20} className={isBoosterRunning ? 'pulse' : ''} />
                            {isBoosterRunning ? 'Boosting...' : 'Booster'}
                        </button>
                    </div>
                </div>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Ticket ID</th>
                            <th>Avatar</th>
                            <th>Name</th>
                            <th>Ticket Description</th>
                            <th>Sentimental Analysis</th>
                            <th>Tags</th>
                            <th>Status</th>
                            <th>Last Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.length > 0 ? (
                            currentItems.map(row => (
                                <React.Fragment key={row.id}>
                                    <tr onClick={() => handleTicketClick(row.id)}>
                                        <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                                        <td>{row.id}</td>
                                        <td>
                                            <div className="avatar" style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                                                {row.avatar}
                                            </div>
                                        </td>
                                        <td>{row.name}</td>
                                        <td>{row.ticketDescription}</td>
                                        <td>{renderStars(row.sentimentalAnalysis)}</td>
                                        <td>{renderTags(row.tags)}</td>
                                        <td>
                                            <span className={`status-box ${getStatusClass(row.status)}`}>
                                                {row.status}
                                            </span>
                                        </td>
                                        <td>{row.updatedAt.toLocaleString()}</td>
                                    </tr>
                                    {expandedRows[row.id] && (
                                        <tr className="expanded-row">
                                            <td colSpan="9">
                                                <div className="ticket-details">
                                                    <h3>{row.ticketDescription}</h3>
                                                    <div className="ticket-comments">
                                                        {row.conversations && row.conversations.map((comment, index) => (
                                                            <div key={index} className={`comment ${comment.sender}`}>
                                                                <div className="comment-sender">
                                                                    {comment.sender === 'customer' ? <UserIcon /> : <SupportIcon />}
                                                                    {comment.sender === 'customer' ? 'Customer' : 'Agent'}
                                                                </div>
                                                                <p>{comment.content}</p>
                                                                <div className="comment-time">{formatDate(comment.timestamp)}</div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="intelligent-ai-email">
                                                        <h4>Intelligent AI Email</h4>
                                                        <ReactQuill
                                                            theme="snow"
                                                            value={emailContent}
                                                            onChange={setEmailContent}
                                                            modules={{
                                                                toolbar: [
                                                                    ['bold', 'italic', 'underline', 'strike'],
                                                                    ['blockquote', 'code-block'],
                                                                    [{ 'header': 1 }, { 'header': 2 }],
                                                                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                                                    [{ 'script': 'sub' }, { 'script': 'super' }],
                                                                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                                                                    [{ 'direction': 'rtl' }],
                                                                    [{ 'size': ['small', false, 'large', 'huge'] }],
                                                                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                    [{ 'color': [] }, { 'background': [] }],
                                                                    [{ 'font': [] }],
                                                                    [{ 'align': [] }],
                                                                    ['clean']
                                                                ],
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9">Loading ticket data...</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="table-footer">
                    <span>Rows per page: {itemsPerPage} ▼</span>
                    <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                    <div className="pagination-controls">
                        <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                        <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;
*/

/* Aug 5 9pmish Tags appear aftering hitting refresh button. We still need to fix the rending of it disapearing and appearing
import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star, RefreshCw, Zap, ArrowLeft } from 'lucide-react';
import './ChatGPT.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isBoosterRunning, setIsBoosterRunning] = useState(false);
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("Current Items:", currentItems);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);


    const [emailContent, setEmailContent] = useState('');

    const [selectedTicket, setSelectedTicket] = useState(null);

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching ticket details:', error);
            return null;
        }
    };
    const fetchTickets = () => {
        setIsRefreshing(true);
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        const formattedTicket = {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            tags: [...(ticket.tags || [])],
                            rawData: ticket
                        };
                        console.log(`Formatted ticket ${ticket.id}:`, formattedTicket);
                        return formattedTicket;
                    });
                    console.log("Formatted ticket data:", formattedData);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        console.log("Table data updated:", tableData);
        tableData.forEach(ticket => {
            console.log(`Ticket ${ticket.id} tags:`, ticket.tags);
        });
    }, [tableData]);

    const handleTicketClick = async (ticketId) => {
        const ticketDetails = await fetchTicketDetails(ticketId);
        if (ticketDetails) {
            setSelectedTicket(ticketDetails);
        }
    };





    const renderTags = React.useCallback((tags) => {
        console.log("Rendering tags for ticket:", tags);
        if (!tags || tags.length === 0) {
            console.log("No tags to render");
            return <span className="no-tags">No tags</span>;
        }
        return (
            <div className="tag-container">
                {tags.map((tag, index) => {
                    console.log(`Rendering tag ${index}:`, tag);
                    return <span key={`${tag}-${index}`} className="tag">{tag}</span>;
                })}
            </div>
        );
    }, []);

    const TicketDetailView = ({ ticket, onBack }) => (
        <div className="ticket-detail">
            <div className="table-header">
                <h2>Ticket Details</h2>
                <div className="button-group">
                    <button className="back-button" onClick={onBack}>
                        <ArrowLeft size={20} />
                        Back to Tickets
                    </button>
                </div>
            </div>
            {ticket ? (
                <>
                    <div className="ticket-subject">{ticket.subject}</div>
                    <div className="ticket-conversations">
                        {ticket.conversations && ticket.conversations.map((message, index) => (
                            <div key={index} className={`message ${message.sender}`}>
                                <p>{message.content}</p>
                                <div className="message-time">
                                    {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p>Loading ticket details...</p>
            )}
        </div>
    );


    useEffect(() => {
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        return {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            rawData: ticket
                        };
                    });
                    formattedData.sort((a, b) => b.updatedAt - a.updatedAt);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            });
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3; // Default to neutral if no description

        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];

        let score = 3; // Neutral starting point

        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });

        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });

        return Math.max(1, Math.min(5, Math.round(score))); // Ensure score is between 1 and 5
    };


    const getAvatarColor = (initial) => {
        if (!initial) return '#000000'; // Default color if no initial is provided
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const renderRow = useCallback((row) => {
        console.log(`Rendering row ${row.id}, tags:`, row.tags);
        return (
            <tr key={row.id} onClick={() => handleTicketClick(row.id)}>
                <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                <td>{row.id}</td>
                <td>
                    <div className="avatar" style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                        {row.avatar}
                    </div>
                </td>
                <td>{row.name}</td>
                <td>{row.ticketDescription}</td>
                <td>{renderStars(row.sentimentalAnalysis)}</td>
                <td>{renderTags(row.tags)}</td>
                <td>{row.status}</td>
                <td>{row.updatedAt.toLocaleString()}</td>
            </tr>
        );
    }, [handleTicketClick, getAvatarColor, renderStars, renderTags]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    const handleBooster = () => {
        setIsBoosterRunning(true);
        axios.post('/api/chat/', { message: 'solve all the tickets', thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log('Booster response:', response.data);
                const newMessage = {
                    user: 'Booster triggered',
                    ai: response.data.message
                };
                setChatHistory(prevHistory => [...prevHistory, newMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }

                // Refresh tickets after booster completes
                fetchTickets();
            })
            .catch(error => {
                console.error('Booster error:', error);
                if (error.response) {
                    console.error('Error response data:', error.response.data);
                    console.error('Error response status:', error.response.status);
                    console.error('Error response headers:', error.response.headers);
                    alert(`Booster Error: ${error.response.data.error}`);
                } else {
                    alert(`Booster Error: ${error.message}`);
                }
            })
            .finally(() => {
                setIsBoosterRunning(false);
            });
    };

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>Jarvis AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                {selectedTicket ? (
                    <TicketDetailView
                        ticket={selectedTicket}
                        onBack={() => setSelectedTicket(null)}
                    />
                ) : (
                    <>
                        <div className="table-header">
                            <h2>Support Tickets</h2>
                            <div className="button-group">
                                <button
                                    className="refresh-button"
                                    onClick={fetchTickets}
                                    disabled={isRefreshing}
                                >
                                    <RefreshCw size={20} className={isRefreshing ? 'spin' : ''} />
                                    {isRefreshing ? 'Refreshing...' : 'Refresh'}
                                </button>
                                <button
                                    className="booster-button"
                                    onClick={handleBooster}
                                    disabled={isBoosterRunning}
                                >
                                    <Zap size={20} className={isBoosterRunning ? 'pulse' : ''} />
                                    {isBoosterRunning ? 'Boosting...' : 'Booster'}
                                </button>
                            </div>
                        </div>
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Ticket ID</th>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th>Ticket Description</th>
                                    <th>Sentimental Analysis</th>
                                    <th>Tags</th>
                                    <th>Status</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map(renderRow)
                                ) : (
                                    <tr>
                                        <td colSpan="8">Loading ticket data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="table-footer">
                            <span>Rows per page: {itemsPerPage} ▼</span>
                            <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                            <div className="pagination-controls">
                                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                            </div>
                        </div>
                    </>
                )}
                <div className="email-editor">
                    <h3>Intelligent AI Email</h3>
                    <ReactQuill
                        theme="snow"
                        value={emailContent}
                        onChange={setEmailContent}
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['clean']
                            ],
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;
*/


/*AUg 5 6:30pm Booster Button Complete, Back to Ticket Button updated

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star, RefreshCw, Zap, ArrowLeft } from 'lucide-react';
import './ChatGPT.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isBoosterRunning, setIsBoosterRunning] = useState(false);
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("Current Items:", currentItems);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);


    const [emailContent, setEmailContent] = useState('');

    const [selectedTicket, setSelectedTicket] = useState(null);

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching ticket details:', error);
            return null;
        }
    };
    const fetchTickets = () => {
        setIsRefreshing(true);
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => ({
                        id: ticket.id,
                        avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                        name: ticket.requester.name || ticket.requester.email || 'Unknown',
                        ticketDescription: ticket.subject || 'No subject',
                        sentimentalAnalysis: calculateSentiment(ticket.description),
                        updatedAt: new Date(ticket.updated_at),
                        status: ticket.status,
                        rawData: ticket
                    }));
                    formattedData.sort((a, b) => b.updatedAt - a.updatedAt);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            })
            .finally(() => {
                setIsRefreshing(false);
            });
    };

    useEffect(() => {
        fetchTickets();
    }, []);

    const handleTicketClick = async (ticketId) => {
        const ticketDetails = await fetchTicketDetails(ticketId);
        if (ticketDetails) {
            setSelectedTicket(ticketDetails);
        }
    };



    const TicketDetailView = ({ ticket, onBack }) => (
        <div className="ticket-detail">
            <div className="table-header">
                <h2>Ticket Details</h2>
                <div className="button-group">
                    <button className="back-button" onClick={onBack}>
                        <ArrowLeft size={20} />
                        Back to Tickets
                    </button>
                </div>
            </div>
            {ticket ? (
                <>
                    <div className="ticket-subject">{ticket.subject}</div>
                    <div className="ticket-conversations">
                        {ticket.conversations && ticket.conversations.map((message, index) => (
                            <div key={index} className={`message ${message.sender}`}>
                                <p>{message.content}</p>
                                <div className="message-time">
                                    {new Date(message.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <p>Loading ticket details...</p>
            )}
        </div>
    );


    useEffect(() => {
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        return {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            rawData: ticket
                        };
                    });
                    formattedData.sort((a, b) => b.updatedAt - a.updatedAt);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            });
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3; // Default to neutral if no description

        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];

        let score = 3; // Neutral starting point

        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });

        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });

        return Math.max(1, Math.min(5, Math.round(score))); // Ensure score is between 1 and 5
    };


    const getAvatarColor = (initial) => {
        if (!initial) return '#000000'; // Default color if no initial is provided
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    const handleBooster = () => {
        setIsBoosterRunning(true);
        axios.post('/api/chat/', { message: 'solve all the tickets', thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log('Booster response:', response.data);
                const newMessage = {
                    user: 'Booster triggered',
                    ai: response.data.message
                };
                setChatHistory(prevHistory => [...prevHistory, newMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }

                // Refresh tickets after booster completes
                fetchTickets();
            })
            .catch(error => {
                console.error('Booster error:', error);
                if (error.response) {
                    console.error('Error response data:', error.response.data);
                    console.error('Error response status:', error.response.status);
                    console.error('Error response headers:', error.response.headers);
                    alert(`Booster Error: ${error.response.data.error}`);
                } else {
                    alert(`Booster Error: ${error.message}`);
                }
            })
            .finally(() => {
                setIsBoosterRunning(false);
            });
    };

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>Jarvis AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                {selectedTicket ? (
                    <TicketDetailView
                        ticket={selectedTicket}
                        onBack={() => setSelectedTicket(null)}
                    />
                ) : (
                    <>
                        <div className="table-header">
                            <h2>Support Tickets</h2>
                            <div className="button-group">
                                <button
                                    className="refresh-button"
                                    onClick={fetchTickets}
                                    disabled={isRefreshing}
                                >
                                    <RefreshCw size={20} className={isRefreshing ? 'spin' : ''} />
                                    {isRefreshing ? 'Refreshing...' : 'Refresh'}
                                </button>
                                <button
                                    className="booster-button"
                                    onClick={handleBooster}
                                    disabled={isBoosterRunning}
                                >
                                    <Zap size={20} className={isBoosterRunning ? 'pulse' : ''} />
                                    {isBoosterRunning ? 'Boosting...' : 'Booster'}
                                </button>
                            </div>
                        </div>
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th>Ticket Description</th>
                                    <th>Sentimental Analysis</th>
                                    <th>Status</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((row, index) => (
                                        <tr key={index} onClick={() => handleTicketClick(row.id)}>
                                            <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                                            <td>
                                                <div className="avatar" style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                                                    {row.avatar}
                                                </div>
                                            </td>
                                            <td>{row.name}</td>
                                            <td>{row.ticketDescription}</td>
                                            <td>{renderStars(row.sentimentalAnalysis)}</td>
                                            <td>{row.status}</td>
                                            <td>{row.updatedAt.toLocaleString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">Loading ticket data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="table-footer">
                            <span>Rows per page: {itemsPerPage} ▼</span>
                            <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                            <div className="pagination-controls">
                                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                            </div>
                        </div>
                    </>
                )}
                <div className="email-editor">
                    <h3>Intelligent AI Email</h3>
                    <ReactQuill
                        theme="snow"
                        value={emailContent}
                        onChange={setEmailContent}
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                [{ 'script': 'sub' }, { 'script': 'super' }],
                                [{ 'indent': '-1' }, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['clean']
                            ],
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;

*/


/*Aug 5 4:25PM Names and Avatar are working including the unknown ones

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star } from 'lucide-react';
import './ChatGPT.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("Current Items:", currentItems);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);
    

    const [emailContent, setEmailContent] = useState('');

    const [selectedTicket, setSelectedTicket] = useState(null);
    

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching ticket details:', error);
            return null;
        }
    };

      const handleTicketClick = async (ticketId) => {
        const ticketDetails = await fetchTicketDetails(ticketId);
        if (ticketDetails) {
          setSelectedTicket(ticketDetails);
        }
      };

    
    
      const TicketDetailView = ({ ticket, onBack }) => (
        <div className="ticket-detail">
          <button className="back-button" onClick={onBack}>Back to Tickets</button>
          {ticket ? (
            <>
              <div className="ticket-subject">{ticket.subject}</div>
              <div className="ticket-conversations">
                {ticket.conversations && ticket.conversations.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <p>{message.content}</p>
                    <div className="message-time">
                      {new Date(message.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p>Loading ticket details...</p>
          )}
        </div>
      );


      useEffect(() => {
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets.map(ticket => {
                        return {
                            id: ticket.id,
                            avatar: (ticket.requester.name[0] || ticket.requester.email[0] || '?').toUpperCase(),
                            name: ticket.requester.name || ticket.requester.email || 'Unknown',
                            ticketDescription: ticket.subject || 'No subject',
                            sentimentalAnalysis: calculateSentiment(ticket.description),
                            updatedAt: new Date(ticket.updated_at),
                            status: ticket.status,
                            rawData: ticket
                        };
                    });
                    formattedData.sort((a, b) => b.updatedAt - a.updatedAt);
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            });
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3; // Default to neutral if no description
    
        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];
    
        let score = 3; // Neutral starting point
    
        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });
    
        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });
    
        return Math.max(1, Math.min(5, Math.round(score))); // Ensure score is between 1 and 5
    };


    const getAvatarColor = (initial) => {
        if (!initial) return '#000000'; // Default color if no initial is provided
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>Jarvis AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                {selectedTicket ? (
                    <TicketDetailView 
                        ticket={selectedTicket} 
                        onBack={() => setSelectedTicket(null)} 
                    />
                ) : (
                    <>
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th>Ticket Description</th>
                                    <th>Sentimental Analysis</th>
                                    <th>Status</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((row, index) => (
                                        <tr key={index} onClick={() => handleTicketClick(row.id)}>
                                            <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                                            <td>
                                                <div className="avatar" style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                                                    {row.avatar}
                                                </div>
                                            </td>
                                            <td>{row.name}</td>
                                            <td>{row.ticketDescription}</td>
                                            <td>{renderStars(row.sentimentalAnalysis)}</td>
                                            <td>{row.status}</td>
                                            <td>{row.updatedAt.toLocaleString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">Loading ticket data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="table-footer">
                            <span>Rows per page: {itemsPerPage} ▼</span>
                            <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                            <div className="pagination-controls">
                                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                            </div>
                        </div>
                    </>
                )}
                <div className="email-editor">
                    <h3>Intelligent AI Email</h3>
                    <ReactQuill
                        theme="snow"
                        value={emailContent}
                        onChange={setEmailContent}
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],
                                [{ 'indent': '-1'}, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['clean']
                            ],
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;
*/


/*
Aug 2 930pm Save the Code before starting tomorrow

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star } from 'lucide-react';
import './ChatGPT.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ChatGPT = () => {
    const [tableData, setTableData] = useState([]);
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);
    console.log("Current Items:", currentItems);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);
    

    const [emailContent, setEmailContent] = useState('');

    const [selectedTicket, setSelectedTicket] = useState(null);
    

    const fetchTicketDetails = async (ticketId) => {
        try {
            const response = await axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
                headers: { 'X-CSRFToken': csrfToken }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching ticket details:', error);
            return null;
        }
    };

      const handleTicketClick = async (ticketId) => {
        const ticketDetails = await fetchTicketDetails(ticketId);
        if (ticketDetails) {
          setSelectedTicket(ticketDetails);
        }
      };

    
    
      const TicketDetailView = ({ ticket, onBack }) => (
        <div className="ticket-detail">
          <button className="back-button" onClick={onBack}>Back to Tickets</button>
          {ticket ? (
            <>
              <div className="ticket-subject">{ticket.subject}</div>
              <div className="ticket-conversations">
                {ticket.conversations && ticket.conversations.map((message, index) => (
                  <div key={index} className={`message ${message.sender}`}>
                    <p>{message.content}</p>
                    <div className="message-time">
                      {new Date(message.timestamp).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <p>Loading ticket details...</p>
          )}
        </div>
      );


      useEffect(() => {
        axios.get('/api/zendesk/list_tickets/', {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                console.log("Raw response data:", response.data);  // Add this line here
                if (response.data && response.data.tickets) {
                    const formattedData = response.data.tickets
                        .filter(ticket => ['open', 'pending', 'new'].includes(ticket.status.toLowerCase()))
                        .map(ticket => {
                            const requesterName = ticket.requester?.name || '';
                            const requesterEmail = ticket.requester?.email || '';
                            return {
                                id: ticket.id,
                                avatar: (requesterName[0] || requesterEmail[0] || '?').toUpperCase(),
                                name: requesterName || requesterEmail || 'Unknown',
                                ticketDescription: ticket.subject || 'No subject',
                                sentimentalAnalysis: calculateSentiment(ticket.description),
                                updatedAt: new Date(ticket.updated_at),
                                status: ticket.status,
                                rawData: ticket
                            };
                        });
                    formattedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
                    setTableData(formattedData);
                    setCurrentPage(1);
                } else {
                    console.error('Unexpected response format:', response.data);
                    setTableData([]);
                }
            })
            .catch(error => {
                console.error('Error fetching tickets:', error);
                setTableData([]);
            });
    }, []);

    const calculateSentiment = (description) => {
        if (!description) return 3; // Default to neutral if no description
    
        const positiveWords = ['good', 'great', 'excellent', 'happy', 'satisfied'];
        const negativeWords = ['bad', 'poor', 'unhappy', 'disappointed', 'angry'];
    
        let score = 3; // Neutral starting point
    
        positiveWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score += 0.5;
        });
    
        negativeWords.forEach(word => {
            if (description.toLowerCase().includes(word)) score -= 0.5;
        });
    
        return Math.max(1, Math.min(5, Math.round(score))); // Ensure score is between 1 and 5
    };


    const getAvatarColor = (initial) => {
        if (!initial) return '#000000'; // Default color if no initial is provided
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };

    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>Jarvis AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                {selectedTicket ? (
                    <TicketDetailView 
                        ticket={selectedTicket} 
                        onBack={() => setSelectedTicket(null)} 
                    />
                ) : (
                    <>
                        <table className="data-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Avatar</th>
                                    <th>Name</th>
                                    <th>Ticket Description</th>
                                    <th>Sentimental Analysis</th>
                                    <th>Status</th>
                                    <th>Last Updated</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.length > 0 ? (
                                    currentItems.map((row, index) => (
                                        <tr key={index} onClick={() => handleTicketClick(row.id)}>
                                            <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                                            <td>
                                                <div className="avatar" style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                                                    {row.avatar}
                                                </div>
                                            </td>
                                            <td>{row.name}</td>
                                            <td>{row.ticketDescription}</td>
                                            <td>{renderStars(row.sentimentalAnalysis)}</td>
                                            <td>{row.status}</td>
                                            <td>{row.updatedAt.toLocaleString()}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="7">Loading ticket data...</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        <div className="table-footer">
                            <span>Rows per page: {itemsPerPage} ▼</span>
                            <span>{indexOfFirstItem + 1}-{Math.min(indexOfLastItem, tableData.length)} of {tableData.length}</span>
                            <div className="pagination-controls">
                                <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}>&#8249;</button>
                                <button onClick={() => setCurrentPage(prev => Math.min(prev + 1, Math.ceil(tableData.length / itemsPerPage)))}>&#8250;</button>
                            </div>
                        </div>
                    </>
                )}
                <div className="email-editor">
                    <h3>Intelligent AI Email</h3>
                    <ReactQuill
                        theme="snow"
                        value={emailContent}
                        onChange={setEmailContent}
                        modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                ['blockquote', 'code-block'],
                                [{ 'header': 1 }, { 'header': 2 }],
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],
                                [{ 'indent': '-1'}, { 'indent': '+1' }],
                                [{ 'direction': 'rtl' }],
                                [{ 'size': ['small', false, 'large', 'huge'] }],
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                [{ 'color': [] }, { 'background': [] }],
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['clean']
                            ],
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;
*/

/* Aug 1 741pm saving before we PULL data directly from Zendesk to populat ethe table
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import { Mic, Speaker, Pin, PinOff, Star } from 'lucide-react';
import './ChatGPT.css';

const ChatGPT = () => {
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);
    const [tableData, setTableData] = useState([
        { avatar: 'N', name: 'Nancy Castillo', ticketDescription: 'I need help porting in', sentimentalAnalysis: 4 },
        { avatar: 'M', name: 'Maud Tate', ticketDescription: 'Whats the status of my order?', sentimentalAnalysis: 4 },
        { avatar: 'M', name: 'Marion Burke', ticketDescription: 'Can you update my phone number?', sentimentalAnalysis: 2 },
        { avatar: 'L', name: 'Linnie Barton', ticketDescription: 'Why is my invoice so high?', sentimentalAnalysis: 3 },
    ]);

    const getAvatarColor = (initial) => {
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581', '#7986CB', '#4DB6AC', '#FFD54F'];
        const index = initial.charCodeAt(0) % colors.length;
        return colors[index];
    };
    const renderStars = (rating) => {
        return Array(5).fill(0).map((_, i) => (
            <Star key={i} size={16} fill={i < rating ? "#FFD700" : "none"} stroke={i < rating ? "#FFD700" : "#6c757d"} />
        ));
    };

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            const newAiMessage = { ai: response.data.message };
            setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(response.data.audio_url);
            }
        })
        .catch(error => console.error('Chat error:', error));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
        })
        .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };



    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500); // 1 seconds delay
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };


    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    return (
        <div className="chat-container">
            <div className="sidebar-hover-trigger"
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <div className={`sidebar ${isSidebarOpen || isSidebarPinned ? 'open' : ''}`}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
                <button className="pin-button" onClick={toggleSidebarPin}>
                    {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                </button>
                <h2>Jarvis AI</h2>
                <div className="chat-history" ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={`message ${chat.user ? 'user-message' : 'ai-message'}`}>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </div>
                    ))}
                </div>
                <div className="audio-controls">
                    <button onClick={toggleRecording} className={`icon-button ${isRecording ? 'recording' : ''}`}>
                        <Mic size={24} />
                    </button>
                    {audioUrl && (
                        <button onClick={toggleAudioPlayback} className={`icon-button ${isPlaying ? 'playing' : ''}`}>
                            <Speaker size={24} />
                        </button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <div className="chat-input-container">
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                        />
                        <button type="submit">Send</button>
                    </form>
                </div>
            </div>
            <div className={`main-content ${isSidebarOpen || isSidebarPinned ? 'sidebar-open' : ''}`}>
                <table className="data-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Avatar</th>
                            <th>Name</th>
                            <th>Ticket Description</th>
                            <th>Sentimental Analysis</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((row, index) => (
                            <tr key={index}>
                                <td><input type="checkbox" /></td>
                                <td>
                                    <div className="avatar" style={{backgroundColor: getAvatarColor(row.avatar)}}>
                                        {row.avatar}
                                    </div>
                                </td>
                                <td>{row.name}</td>
                                <td>{row.ticketDescription}</td>
                                <td>{renderStars(row.sentimentalAnalysis)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="table-footer">
                    <span>Rows per page: 100 ▼</span>
                    <span>1-100 of 1000</span>
                    <div className="pagination-controls">
                        <button>&#8249;</button>
                        <button>&#8250;</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ChatGPT;

*/


/* July 31 saving code before major updates
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Ref to manage the audio element

    // Zendesk states
    const [ticketSubject, setTicketSubject] = useState('');
    const [ticketDescription, setTicketDescription] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [ticket, setTicket] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [latestTicket, setLatestTicket] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
            console.log('Transcription:', response.data.text);  // Log for debugging
        })
        .catch(error => console.error('STT error:', error));
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = []; // Clear audio chunks after sending to STT
        };
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.load();
            audioRef.current.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
    }, [audioUrl]);

    // Zendesk functions
    const handleCreateTicket = (e) => {
        e.preventDefault();
        axios.post('/api/zendesk/create_ticket/', { ticket: { subject: ticketSubject, description: ticketDescription } }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket created:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Create ticket error:', error));
    };

    const handleRetrieveTicket = (e) => {
        e.preventDefault();
        axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket retrieved:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Retrieve ticket error:', error));
    };

    const handleGetLatestTicket = (e) => {
        e.preventDefault();
        axios.get(`/api/zendesk/get_latest_ticket_for_user/`, {
            headers: { 'X-CSRFToken': csrfToken },
            params: { email: userEmail }
        })
        .then(response => {
            console.log('Latest ticket:', response.data);
            setLatestTicket(response.data);
        })
        .catch(error => console.error('Get latest ticket error:', error));
    };

    const handleSearchTickets = (e) => {
        e.preventDefault();
        axios.get('/api/zendesk/search_tickets/', {
            headers: { 'X-CSRFToken': csrfToken },
            params: { query: searchQuery }
        })
        .then(response => {
            console.log('Tickets found:', response.data);
            setSearchResults(response.data.results);
        })
        .catch(error => console.error('Search tickets error:', error));
    };

    const handleBooster = () => {
        axios.post('/api/chat/', { message: 'solve all the tickets', thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Booster response:', response.data);
            const newMessage = {
                user: 'Booster triggered',
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setThreadId(response.data.thread_id);
    
            if (response.data.audio_url) {
                setAudioUrl(response.data.audio_url);
            }
        })
        .catch(error => {
            console.error('Booster error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Booster Error: ${error.response.data.error}`);
            } else {
                alert(`Booster Error: ${error.message}`);
            }
        });
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <pre><strong>AI:</strong> <span dangerouslySetInnerHTML={{ __html: chat.ai }} /></pre>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="button"
                    >
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <pre dangerouslySetInnerHTML={{ __html: aiMessage }} />
                            <audio ref={audioRef} controls>
                                {audioUrl && <source src={audioUrl} type="audio/mpeg" />}
                                Your browser does not support the audio element.
                            </audio>
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}

                    
                    <form onSubmit={handleCreateTicket} className="form">
                        <h2>Create Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketSubject}
                            onChange={(e) => setTicketSubject(e.target.value)}
                            placeholder="Ticket Subject"
                            className="input"
                        />
                        <textarea
                            value={ticketDescription}
                            onChange={(e) => setTicketDescription(e.target.value)}
                            placeholder="Ticket Description"
                            className="input"
                        />
                        <button type="submit" className="button">Create Ticket</button>
                    </form>
                    {ticket && (
                        <div className="ticket-info">
                            <h2>Ticket Info:</h2>
                            <p>ID: {ticket.id}</p>
                            <p>Subject: {ticket.subject}</p>
                            <p>Description: {ticket.description}</p>
                        </div>
                    )}
                    <form onSubmit={handleRetrieveTicket} className="form">
                        <h2>Retrieve Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketId}
                            onChange={(e) => setTicketId(e.target.value)}
                            placeholder="Ticket ID"
                            className="input"
                        />
                        <button type="submit" className="button">Retrieve Ticket</button>
                    </form>
                    <form onSubmit={handleSearchTickets} className="form">
                        <h2>Search Zendesk Tickets</h2>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search Query"
                            className="input"
                        />
                        <button type="submit" className="button">Search Tickets</button>
                    </form>
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            <h2>Search Results</h2>
                            <ul>
                                {searchResults.map((result) => (
                                    <li key={result.id}>{result.subject}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <button onClick={handleBooster} className="button">Booster</button>
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/


/* July 16th 6pm saving before we update and add the BOOST. This code works for all Zendesk stuff"

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Ref to manage the audio element

    // Zendesk states
    const [ticketSubject, setTicketSubject] = useState('');
    const [ticketDescription, setTicketDescription] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [ticket, setTicket] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const [latestTicket, setLatestTicket] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
            console.log('Transcription:', response.data.text);  // Log for debugging
        })
        .catch(error => console.error('STT error:', error));
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = []; // Clear audio chunks after sending to STT
        };
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.load();
            audioRef.current.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
    }, [audioUrl]);

    // Zendesk functions
    const handleCreateTicket = (e) => {
        e.preventDefault();
        axios.post('/api/zendesk/create_ticket/', { ticket: { subject: ticketSubject, description: ticketDescription } }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket created:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Create ticket error:', error));
    };
    
    

    const handleRetrieveTicket = (e) => {
        e.preventDefault();
        axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket retrieved:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Retrieve ticket error:', error));
    };

        const handleGetLatestTicket = (e) => {
        e.preventDefault();
        axios.get(`/api/zendesk/get_latest_ticket_for_user/`, {
            headers: { 'X-CSRFToken': csrfToken },
            params: { email: userEmail }
        })
        .then(response => {
            console.log('Latest ticket:', response.data);
            setLatestTicket(response.data);
        })
        .catch(error => console.error('Get latest ticket error:', error));
    };


    const handleSearchTickets = (e) => {
        e.preventDefault();
        axios.get('/api/zendesk/search_tickets/', {
            headers: { 'X-CSRFToken': csrfToken },
            params: { query: searchQuery }
        })
        .then(response => {
            console.log('Tickets found:', response.data);
            setSearchResults(response.data.results);
        })
        .catch(error => console.error('Search tickets error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <pre><strong>AI:</strong> <span dangerouslySetInnerHTML={{ __html: chat.ai }} /></pre>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="button"
                    >
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <pre dangerouslySetInnerHTML={{ __html: aiMessage }} />
                            <audio ref={audioRef} controls>
                                {audioUrl && <source src={audioUrl} type="audio/mpeg" />}
                                Your browser does not support the audio element.
                            </audio>
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}

                    
                    <form onSubmit={handleCreateTicket} className="form">
                        <h2>Create Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketSubject}
                            onChange={(e) => setTicketSubject(e.target.value)}
                            placeholder="Ticket Subject"
                            className="input"
                        />
                        <textarea
                            value={ticketDescription}
                            onChange={(e) => setTicketDescription(e.target.value)}
                            placeholder="Ticket Description"
                            className="input"
                        />
                        <button type="submit" className="button">Create Ticket</button>
                    </form>
                    {ticket && (
                        <div className="ticket-info">
                            <h2>Ticket Info:</h2>
                            <p>ID: {ticket.id}</p>
                            <p>Subject: {ticket.subject}</p>
                            <p>Description: {ticket.description}</p>
                        </div>
                    )}
                    <form onSubmit={handleRetrieveTicket} className="form">
                        <h2>Retrieve Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketId}
                            onChange={(e) => setTicketId(e.target.value)}
                            placeholder="Ticket ID"
                            className="input"
                        />
                        <button type="submit" className="button">Retrieve Ticket</button>
                    </form>
                    <form onSubmit={handleSearchTickets} className="form">
                        <h2>Search Zendesk Tickets</h2>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search Query"
                            className="input"
                        />
                        <button type="submit" className="button">Search Tickets</button>
                    </form>
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            <h2>Search Results</h2>
                            <ul>
                                {searchResults.map((result) => (
                                    <li key={result.id}>{result.subject}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/

/*Jul 1 820pm Includes the Zendesk stuff and it's able to create,retrieve,search */
/*
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Ref to manage the audio element

    // Zendesk states
    const [ticketSubject, setTicketSubject] = useState('');
    const [ticketDescription, setTicketDescription] = useState('');
    const [ticketId, setTicketId] = useState('');
    const [ticket, setTicket] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
            console.log('Transcription:', response.data.text);  // Log for debugging
        })
        .catch(error => console.error('STT error:', error));
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = []; // Clear audio chunks after sending to STT
        };
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.load();
            audioRef.current.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
    }, [audioUrl]);

    // Zendesk functions
    const handleCreateTicket = (e) => {
        e.preventDefault();
        axios.post('/api/zendesk/create_ticket/', { ticket: { subject: ticketSubject, description: ticketDescription } }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket created:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Create ticket error:', error));
    };
    
    

    const handleRetrieveTicket = (e) => {
        e.preventDefault();
        axios.get(`/api/zendesk/retrieve_ticket/${ticketId}/`, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('Ticket retrieved:', response.data);
            setTicket(response.data);
        })
        .catch(error => console.error('Retrieve ticket error:', error));
    };

    const handleSearchTickets = (e) => {
        e.preventDefault();
        axios.get('/api/zendesk/search_tickets/', {
            headers: { 'X-CSRFToken': csrfToken },
            params: { query: searchQuery }
        })
        .then(response => {
            console.log('Tickets found:', response.data);
            setSearchResults(response.data.results);
        })
        .catch(error => console.error('Search tickets error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <pre><strong>AI:</strong> <span dangerouslySetInnerHTML={{ __html: chat.ai }} /></pre>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="button"
                    >
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <pre dangerouslySetInnerHTML={{ __html: aiMessage }} />
                            <audio ref={audioRef} controls>
                                {audioUrl && <source src={audioUrl} type="audio/mpeg" />}
                                Your browser does not support the audio element.
                            </audio>
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}

                    
                    <form onSubmit={handleCreateTicket} className="form">
                        <h2>Create Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketSubject}
                            onChange={(e) => setTicketSubject(e.target.value)}
                            placeholder="Ticket Subject"
                            className="input"
                        />
                        <textarea
                            value={ticketDescription}
                            onChange={(e) => setTicketDescription(e.target.value)}
                            placeholder="Ticket Description"
                            className="input"
                        />
                        <button type="submit" className="button">Create Ticket</button>
                    </form>
                    {ticket && (
                        <div className="ticket-info">
                            <h2>Ticket Info:</h2>
                            <p>ID: {ticket.id}</p>
                            <p>Subject: {ticket.subject}</p>
                            <p>Description: {ticket.description}</p>
                        </div>
                    )}
                    <form onSubmit={handleRetrieveTicket} className="form">
                        <h2>Retrieve Zendesk Ticket</h2>
                        <input
                            type="text"
                            value={ticketId}
                            onChange={(e) => setTicketId(e.target.value)}
                            placeholder="Ticket ID"
                            className="input"
                        />
                        <button type="submit" className="button">Retrieve Ticket</button>
                    </form>
                    <form onSubmit={handleSearchTickets} className="form">
                        <h2>Search Zendesk Tickets</h2>
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search Query"
                            className="input"
                        />
                        <button type="submit" className="button">Search Tickets</button>
                    </form>
                    {searchResults.length > 0 && (
                        <div className="search-results">
                            <h2>Search Results</h2>
                            <ul>
                                {searchResults.map((result) => (
                                    <li key={result.id}>{result.subject}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;

*/

/*July 1 633pm this code keeps the same format of AI where it's able to indent as well as make the invoice link when creating an invoice 
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Ref to manage the audio element

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
            console.log('Transcription:', response.data.text);  // Log for debugging
        })
        .catch(error => console.error('STT error:', error));
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = []; // Clear audio chunks after sending to STT
        };
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.load();
            audioRef.current.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
    }, [audioUrl]);

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <pre><strong>AI:</strong> <span dangerouslySetInnerHTML={{ __html: chat.ai }} /></pre>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="button"
                    >
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <pre dangerouslySetInnerHTML={{ __html: aiMessage }} />
                            <audio ref={audioRef} controls>
                                {audioUrl && <source src={audioUrl} type="audio/mpeg" />}
                                Your browser does not support the audio element.
                            </audio>
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/


/* July 1 2024 WORKING
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const audioRef = useRef(null);  // Ref to manage the audio element

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            setUserMessage(response.data.text);
            console.log('Transcription:', response.data.text);  // Log for debugging
        })
        .catch(error => console.error('STT error:', error));
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = []; // Clear audio chunks after sending to STT
        };
    };

    useEffect(() => {
        if (audioUrl && audioRef.current) {
            audioRef.current.load();
            audioRef.current.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
    }, [audioUrl]);

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <p><strong>AI:</strong> {chat.ai}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <button
                        onClick={isRecording ? stopRecording : startRecording}
                        className="button"
                    >
                        {isRecording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <p>{aiMessage}</p>
                            <audio ref={audioRef} controls>
                                {audioUrl && <source src={audioUrl} type="audio/mpeg" />}
                                Your browser does not support the audio element.
                            </audio>
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/


/* May 20 1PM working with stripe, firstname and last name
import React, { useState } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setUserMessage(response.data.text))
        .catch(error => console.error('STT error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <p><strong>AI:</strong> {chat.ai}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <input type="file" onChange={handleSTT} className="button" />
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <p>{aiMessage}</p>
                            {audioUrl && <audio controls src={audioUrl} autoPlay />}
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/

/* May 19 11:30pm working audio after each Ai response and natural response that leverages assistant API import React, { useState } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [followUpMessage, setFollowUpMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);

            if (response.data.audio_url) {
                setAudioUrl(null);  // Clear the previous audio URL
                setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
            } else {
                generateTTS(response.data.message);
            }
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };

    const generateTTS = (text) => {
        axios.post('/api/tts/', { text }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('TTS response:', response.data);
            setAudioUrl(null);  // Clear the previous audio URL
            setTimeout(() => setAudioUrl(response.data.audio_url), 0);  // Set the new audio URL after clearing
        })
        .catch(error => {
            console.error('TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`TTS Error: ${error.response.data.error}`);
            } else {
                alert(`TTS Error: ${error.message}`);
            }
        });
    };

    const handleSTT = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setUserMessage(response.data.text))
        .catch(error => console.error('STT error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <p><strong>AI:</strong> {chat.ai}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <input type="file" onChange={handleSTT} className="button" />
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <p>{aiMessage}</p>
                            {audioUrl && <audio controls src={audioUrl} autoPlay />}
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/

/* Working with Audo May 19 930 pm import React, { useState } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);  // To keep track of conversation thread
    const [followUpMessage, setFollowUpMessage] = useState('');  // New state for follow-up messages

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);
    
        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);
    
            axios.post('/api/tts/', { text: response.data.message }, {
                headers: { 'X-CSRFToken': csrfToken },
                responseType: 'arraybuffer'
            })
            .then(res => {
                console.log('TTS response:', res);
                const audioBlob = new Blob([res.data], { type: 'audio/mpeg' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioUrl(audioUrl);
            })
            .catch(error => {
                console.error('TTS error:', error);
                if (error.response) {
                    console.error('Error response data:', error.response.data);
                    console.error('Error response status:', error.response.status);
                    console.error('Error response headers:', error.response.headers);
                    alert(`TTS Error: ${error.response.data.error}`);
                } else {
                    alert(`TTS Error: ${error.message}`);
                }
            });
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
                alert(`Chat Error: ${error.response.data.error}`);
            } else {
                alert(`Chat Error: ${error.message}`);
            }
        });
    };
    
    const handleSTT = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setUserMessage(response.data.text))
        .catch(error => console.error('STT error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <p><strong>AI:</strong> {chat.ai}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <input type="file" onChange={handleSTT} className="button" />
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <p>{aiMessage}</p>
                            {audioUrl && <audio controls src={audioUrl} />}
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/

/*import React, { useState } from 'react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import './ChatGPT.css';

const ChatGPT = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [customer, setCustomer] = useState(null);
    const [userMessage, setUserMessage] = useState('');
    const [aiMessage, setAiMessage] = useState('');
    const [audioUrl, setAudioUrl] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);  // To keep track of conversation thread
    const [followUpMessage, setFollowUpMessage] = useState('');  // New state for follow-up messages

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/api/create-customer/', { name, email }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setCustomer(response.data))
        .catch(error => console.error('Create customer error:', error));
    };

    const handleChatSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(userMessage);
    };

    const handleFollowUpSubmit = (e) => {
        e.preventDefault();
        sendMessageToAI(followUpMessage);
        setFollowUpMessage('');
    };

    const sendMessageToAI = (message) => {
        console.log('Sending message to AI:', message);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => {
            console.log('AI response:', response.data);
            const newMessage = {
                user: message,
                ai: response.data.message
            };
            setChatHistory([...chatHistory, newMessage]);
            setAiMessage(response.data.message);
            setUserMessage('');
            setThreadId(response.data.thread_id);  // Update thread ID

            axios.post('/api/tts/', { text: response.data.message }, {
                headers: { 'X-CSRFToken': csrfToken }
            })
            .then(res => {
                const audioBlob = new Blob([res.data], { type: 'audio/mpeg' });
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioUrl(audioUrl);
            })
            .catch(error => console.error('TTS error:', error));
        })
        .catch(error => {
            console.error('Chat or TTS error:', error);
            if (error.response) {
                console.error('Error response data:', error.response.data);
                console.error('Error response status:', error.response.status);
                console.error('Error response headers:', error.response.headers);
            }
        });
    };

    const handleSTT = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
        .then(response => setUserMessage(response.data.text))
        .catch(error => console.error('STT error:', error));
    };

    return (
        <div className="chatgpt-container">
            <div className="sidebar">
                <h2>Chat History</h2>
                <ul>
                    {chatHistory.map((chat, index) => (
                        <li key={index}>
                            <p><strong>You:</strong> {chat.user}</p>
                            <p><strong>AI:</strong> {chat.ai}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="main">
                <header className="header">
                    <form onSubmit={handleSubmit} className="form">
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Name"
                            className="input"
                        />
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            className="input"
                        />
                        <button type="submit" className="button">Create Customer</button>
                    </form>
                    {customer && (
                        <div className="customer-info">
                            <h2>Customer Created:</h2>
                            <p>ID: {customer.id}</p>
                            <p>Name: {customer.name}</p>
                            <p>Email: {customer.email}</p>
                        </div>
                    )}
                    <form onSubmit={handleChatSubmit} className="form">
                        <input
                            type="text"
                            value={userMessage}
                            onChange={(e) => setUserMessage(e.target.value)}
                            placeholder="Ask me anything"
                            className="input"
                        />
                        <button type="submit" className="button">Send</button>
                    </form>
                    <input type="file" onChange={handleSTT} className="button" />
                    {aiMessage && (
                        <div className="ai-response">
                            <h2>AI Response:</h2>
                            <p>{aiMessage}</p>
                            {audioUrl && <audio controls src={audioUrl} />}
                            <form onSubmit={handleFollowUpSubmit} className="form">
                                <input
                                    type="text"
                                    value={followUpMessage}
                                    onChange={(e) => setFollowUpMessage(e.target.value)}
                                    placeholder="Your response"
                                    className="input"
                                />
                                <button type="submit" className="button">Send</button>
                            </form>
                        </div>
                    )}
                </header>
            </div>
        </div>
    );
};

export default ChatGPT;
*/