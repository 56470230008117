import React, { useCallback, lazy, Suspense, useEffect } from 'react';
import { motion } from 'framer-motion';
import Particles from "react-particles";
import { loadFull } from "tsparticles";
import { Link } from 'react-router-dom';
import { ArrowRight, ArrowUp, Code, Globe, Zap, Clock, SmilePlus, ShieldCheck } from 'lucide-react';
import styles from './HomePage.module.css';

const SignupSection = lazy(() => import('./SignupSection'));
const ContactForm = lazy(() => import('./ContactForm'));

const HomePage = () => {
    const [showScrollTop, setShowScrollTop] = React.useState(false);

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const smoothScroll = (e, target) => {
        e.preventDefault();
        document.querySelector(target).scrollIntoView({
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.pageYOffset > 300) {
            setShowScrollTop(true);
        } else {
            setShowScrollTop(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.history.scrollRestoration = 'manual';
        window.scrollTo(0, 0);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={styles.homeContainer}>
            <Particles
                id="tsparticles"
                init={particlesInit}
                options={{
                    background: { color: { value: "transparent" } },
                    fpsLimit: 60,
                    interactivity: {
                        events: {
                            onClick: { enable: true, mode: "push" },
                            onHover: { enable: true, mode: "repulse" },
                            resize: true,
                        },
                        modes: {
                            push: { quantity: 4 },
                            repulse: { distance: 200, duration: 0.4 },
                        },
                    },
                    particles: {
                        color: { value: "#5eead4" },
                        links: {
                            color: "#5eead4",
                            distance: 150,
                            enable: true,
                            opacity: 0.5,
                            width: 1,
                        },
                        move: {
                            direction: "none",
                            enable: true,
                            outModes: { default: "bounce" },
                            random: false,
                            speed: 2,
                            straight: false,
                        },
                        number: { density: { enable: true, area: 800 }, value: 80 },
                        opacity: { value: 0.5 },
                        shape: { type: "circle" },
                        size: { value: { min: 1, max: 5 } },
                    },
                    detectRetina: true,
                }}
            />
            <header className={styles.header}>
                <Link to="/" className={styles.logo}>
                    <motion.div
                        initial={{ rotate: 0 }}
                        animate={{ rotate: 360 }}
                        transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
                    >
                        N
                    </motion.div>
                </Link>
                <nav className={styles.nav}>
                    <a href="#about" onClick={(e) => smoothScroll(e, '#about')}>About</a>
                    <a href="#features" onClick={(e) => smoothScroll(e, '#features')}>Features</a>
                    <a href="#benefits" onClick={(e) => smoothScroll(e, '#benefits')}>Benefits</a>
                    <a href="#contact" onClick={(e) => smoothScroll(e, '#contact')}>Contact</a>
                    <Link to="/chat" className={styles.ctaButton}>Try Demo</Link>
                </nav>
            </header>

            <main className={styles.mainContent}>
                <section className={styles.heroSection}>
                    <motion.h1
                        className={styles.title}
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                    >
                        Naack
                    </motion.h1>
                    <motion.p
                        className={styles.tagline}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2, ease: "easeOut" }}
                    >
                        AI-Orchestrated Integration for Seamless Enterprise Operations
                    </motion.p>
                    <motion.p
                        className={styles.subheadline}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.4, ease: "easeOut" }}
                    >
                        Empower AI to coordinate actions across multiple systems, delivering comprehensive solutions through natural language requests.
                    </motion.p>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <Link to="/chat" className={styles.heroCtaButton}>
                            Try Demo <ArrowRight size={20} />
                        </Link>
                    </motion.div>
                </section>

                <Suspense fallback={<div className={styles.loadingSpinner}>Loading...</div>}>
                    <SignupSection />
                </Suspense>

                <motion.section
                    id="about"
                    className={styles.section}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <h2 className={styles.sectionTitle}>About Naack</h2>
                    <p className={styles.aboutText}>
                        Naack is revolutionizing enterprise operations by bridging the gap between natural language and complex API systems. Our cutting-edge AI technology interprets human requests and translates them into precise API calls, enabling seamless interaction across multiple platforms like Zendesk and Stripe.
                    </p>
                </motion.section>

                <motion.section
                    id="features"
                    className={styles.section}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <h2 className={styles.sectionTitle}>How Naack Works</h2>
                    <div className={styles.featuresGrid}>
                        <motion.div
                            className={styles.featureCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <Code size={48} className={styles.featureIcon} />
                            <h3>Natural Language to API</h3>
                            <p>Transform natural language requests into precise API calls across multiple systems, including Zendesk and Stripe.</p>
                        </motion.div>
                        <motion.div
                            className={styles.featureCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <Globe size={48} className={styles.featureIcon} />
                            <h3>Multi-System Integration</h3>
                            <p>Seamlessly connect and orchestrate actions across various enterprise platforms, centralizing your support operations.</p>
                        </motion.div>
                        <motion.div
                            className={styles.featureCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <Zap size={48} className={styles.featureIcon} />
                            <h3>AI-Powered Automation</h3>
                            <p>Leverage advanced AI to automate complex workflows and decision-making processes, enhancing efficiency and accuracy.</p>
                        </motion.div>
                    </div>
                </motion.section>

                <motion.section
                    id="benefits"
                    className={styles.section}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <h2 className={styles.sectionTitle}>Benefits</h2>
                    <div className={styles.benefitsGrid}>
                        <motion.div
                            className={styles.benefitCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <Clock size={48} className={styles.benefitIcon} />
                            <h3>Time Savings</h3>
                            <p>Automate responses and streamline workflows to significantly reduce response times and increase productivity.</p>
                        </motion.div>
                        <motion.div
                            className={styles.benefitCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <SmilePlus size={48} className={styles.benefitIcon} />
                            <h3>Improved Customer Satisfaction</h3>
                            <p>Provide faster, more accurate responses to customer inquiries, enhancing overall satisfaction and loyalty.</p>
                        </motion.div>
                        <motion.div
                            className={styles.benefitCard}
                            whileHover={{ scale: 1.05 }}
                            transition={{ type: "spring", stiffness: 300 }}
                        >
                            <ShieldCheck size={48} className={styles.benefitIcon} />
                            <h3>Reduced Errors</h3>
                            <p>Minimize human errors in data entry and information retrieval with AI-powered automation and integration.</p>
                        </motion.div>
                    </div>
                </motion.section>

{/*	    
                <section id="demo" className={styles.section}>
                    <h2 className={styles.sectionTitle}>See Naack in Action</h2>
                    <div className={styles.demoContainer}>
	    
                        <div className={styles.demoPlaceholder}>
                            Animated Demo or Video Placeholder
                        </div>
                    </div>
                </section>

*/}

                <section id="contact" className={styles.section}>
                    <h2 className={styles.sectionTitle}>Get in Touch</h2>
                    <Suspense fallback={<div className={styles.loadingSpinner}>Loading...</div>}>
                        <ContactForm />
                    </Suspense>
                </section>
            </main>

            <footer className={styles.footer}>
                <div className={styles.footerContent}>
                    <div className={styles.footerSection}>
                        <h3>Naack</h3>
                        <p>Revolutionizing enterprise operations with AI-powered API orchestration.</p>
                    </div>
                    <div className={styles.footerSection}>
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a href="#about" onClick={(e) => smoothScroll(e, '#about')}>About</a></li>
                            <li><a href="#features" onClick={(e) => smoothScroll(e, '#features')}>Features</a></li>
                            <li><a href="#benefits" onClick={(e) => smoothScroll(e, '#benefits')}>Benefits</a></li>
                            <li><a href="#contact" onClick={(e) => smoothScroll(e, '#contact')}>Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <p>&copy; 2024 Naack. All rights reserved.</p>
                </div>
            </footer>

            <motion.button
                className={styles.scrollTopButton}
                onClick={scrollToTop}
                initial={{ opacity: 0 }}
                animate={{ opacity: showScrollTop ? 1 : 0 }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <ArrowUp size={24} />
            </motion.button>
        </div>
    );
};

export default HomePage;
