import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mic, Speaker, Pin, PinOff, Send, User, Bot, Trash2 } from 'lucide-react';
import axios from 'axios';
import { csrfToken } from '../utils/csrf';
import styles from './ChatSidebar.module.css';

const ChatSidebar = () => {
    const [userMessage, setUserMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [threadId, setThreadId] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const [audioUrl, setAudioUrl] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isSidebarPinned, setIsSidebarPinned] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const chatContainerRef = useRef(null);
    const audioRef = useRef(null);
    const mediaRecorder = useRef(null);
    const audioChunks = useRef([]);
    const sidebarTimerRef = useRef(null);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatHistory]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.onended = () => setIsPlaying(false);
        }
    }, [audioUrl]);

    const handleSendMessage = (e) => {
        e.preventDefault();
        if (userMessage.trim()) {
            sendMessageToAI(userMessage);
        }
    };

    const sendMessageToAI = (message) => {
        const newUserMessage = { user: message };
        setChatHistory(prevHistory => [...prevHistory, newUserMessage]);
        setUserMessage('');
        setIsLoading(true);

        axios.post('/api/chat/', { message: message, thread_id: threadId }, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                const newAiMessage = { ai: response.data.message };
                setChatHistory(prevHistory => [...prevHistory, newAiMessage]);
                setThreadId(response.data.thread_id);

                if (response.data.audio_url) {
                    setAudioUrl(response.data.audio_url);
                }
            })
            .catch(error => {
                console.error('Chat error:', error);
                setChatHistory(prevHistory => [...prevHistory, { ai: "I'm sorry, I encountered an error. Please try again." }]);
            })
            .finally(() => setIsLoading(false));
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    const startRecording = () => {
        setIsRecording(true);
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                mediaRecorder.current = new MediaRecorder(stream);
                mediaRecorder.current.ondataavailable = (event) => {
                    audioChunks.current.push(event.data);
                };
                mediaRecorder.current.start();
            })
            .catch(error => {
                console.error('Error accessing microphone:', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorder.current.stop();
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            handleSTT(audioBlob);
            audioChunks.current = [];
        };
    };

    const handleSTT = (audioBlob) => {
        const formData = new FormData();
        formData.append('file', audioBlob, 'audio.wav');
        axios.post('/api/stt/', formData, {
            headers: { 'X-CSRFToken': csrfToken }
        })
            .then(response => {
                setUserMessage(response.data.text);
            })
            .catch(error => console.error('STT error:', error));
    };

    const toggleAudioPlayback = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleSidebarHover = (isHovered) => {
        if (isHovered) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        } else if (!isSidebarPinned) {
            sidebarTimerRef.current = setTimeout(() => {
                setIsSidebarOpen(false);
            }, 500);
        }
    };

    const toggleSidebarPin = () => {
        setIsSidebarPinned(!isSidebarPinned);
        if (!isSidebarPinned) {
            setIsSidebarOpen(true);
            if (sidebarTimerRef.current) {
                clearTimeout(sidebarTimerRef.current);
            }
        }
    };

    const clearChat = () => {
        setChatHistory([]);
        setThreadId(null);
    };

    return (
        <>
            <div className={styles.sidebarHoverTrigger}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}>
            </div>
            <motion.div 
                className={`${styles.sidebar} ${isSidebarOpen || isSidebarPinned ? styles.open : ''}`}
                initial={{ x: '-100%' }}
                animate={{ x: isSidebarOpen || isSidebarPinned ? 0 : '-100%' }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                onMouseEnter={() => handleSidebarHover(true)}
                onMouseLeave={() => handleSidebarHover(false)}
            >
                <div className={styles.sidebarHeader}>
                    <h2 className={styles.sidebarTitle}>NAACK AI</h2>
                    <button className={styles.pinButton} onClick={toggleSidebarPin}>
                        {isSidebarPinned ? <PinOff size={20} /> : <Pin size={20} />}
                    </button>
                    <button className={styles.clearButton} onClick={clearChat}>
                        <Trash2 size={20} />
                    </button>
                </div>
                <div className={styles.chatHistory} ref={chatContainerRef}>
                    {chatHistory.map((chat, index) => (
                        <motion.div 
                            key={index} 
                            className={`${styles.message} ${chat.user ? styles.userMessage : styles.aiMessage}`}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <div className={styles.messageIcon}>
                                {chat.user ? <User size={16} /> : <Bot size={16} />}
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: chat.user || chat.ai }} />
                        </motion.div>
                    ))}
                    {isLoading && (
                        <motion.div 
                            className={`${styles.message} ${styles.aiMessage} ${styles.loadingMessage}`}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                        >
                            <div className={styles.messageIcon}>
                                <Bot size={16} />
                            </div>
                            <div className={styles.typingIndicator}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </motion.div>
                    )}
                </div>
                <div className={styles.audioControls}>
                    <motion.button 
                        onClick={toggleRecording} 
                        className={`${styles.iconButton} ${isRecording ? styles.recording : ''}`}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                    >
                        <Mic size={24} />
                    </motion.button>
                    {audioUrl && (
                        <motion.button 
                            onClick={toggleAudioPlayback} 
                            className={`${styles.iconButton} ${isPlaying ? styles.playing : ''}`}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Speaker size={24} />
                        </motion.button>
                    )}
                    <audio ref={audioRef} src={audioUrl} />
                </div>
                <form onSubmit={handleSendMessage} className={styles.chatInputContainer}>
                    <input
                        type="text"
                        value={userMessage}
                        onChange={(e) => setUserMessage(e.target.value)}
                        placeholder="Ask me anything"
                        className={styles.chatInput}
                        maxLength={500}
                    />
                    <motion.button 
                        type="submit" 
                        className={styles.sendButton}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        disabled={!userMessage.trim() || isLoading}
                    >
                        <Send size={20} />
                    </motion.button>
                </form>
            </motion.div>
        </>
    );
};

export default ChatSidebar;