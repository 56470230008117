import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronUp, ArrowUpDown, Send, AlertCircle, Check, Clock, Paperclip, MoreHorizontal, ChevronRight, ChevronLeft } from 'lucide-react';
import { FaUser as UserIcon, FaHeadset as SupportIcon } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './TicketTable.module.css';

const TicketTable = ({ 
    currentItems, 
    handleTicketClick, 
    expandedRows, 
    renderTags, 
    getStatusClass, 
    getAvatarColor, 
    formatDate,
    emailContent,
    setEmailContent,
    onSort,
    currentPage,
    totalPages,
    onPageChange,
    fetchTicketDetails
}) => {
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [compactView, setCompactView] = useState(false);
    const [loadingTickets, setLoadingTickets] = useState({});

    const renderSentiment = (score) => {
        const sentiments = [
            { color: '#ff4d4d', label: 'Very Negative' },
            { color: '#ff9999', label: 'Negative' },
            { color: '#ffdd99', label: 'Neutral' },
            { color: '#99ff99', label: 'Positive' },
            { color: '#4dff4d', label: 'Very Positive' }
        ];
        const index = Math.min(Math.floor(score) - 1, 4);
        return (
            <div className={styles.sentimentIndicator} style={{ backgroundColor: sentiments[index].color }}>
                {sentiments[index].label}
            </div>
        );
    };

    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
        onSort(column, sortDirection === 'asc' ? 'desc' : 'asc');
    };

    const renderSortIcon = (column) => {
        if (sortColumn !== column) return <ArrowUpDown size={16} />;
        return sortDirection === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />;
    };

    const handleExpandTicket = async (ticketId) => {
        if (!expandedRows[ticketId]) {
            setLoadingTickets(prev => ({ ...prev, [ticketId]: true }));
            await fetchTicketDetails(ticketId);
            setLoadingTickets(prev => ({ ...prev, [ticketId]: false }));
        }
        handleTicketClick(ticketId);
    };

    const renderExpandedRow = (row) => (
        <motion.tr 
            className={styles.expandedRow}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
        >
            <td colSpan="9">
                <div className={styles.expandedContent}>
                    {loadingTickets[row.id] ? (
                        <div className={styles.loadingContainer}>
                            <div className={styles.loadingSpinner}></div>
                            <p>Loading ticket details...</p>
                        </div>
                    ) : (
                        <>
                            <div className={styles.ticketSummary}>
                                <h3>{row.ticketDescription}</h3>
                                <StatusBadge status={row.status} />
                            </div>
                            <div className={styles.quickActions}>
                                <button className={styles.actionButton}>Mark as Resolved</button>
                                <button className={styles.actionButton}>Escalate</button>
                                <button className={styles.actionButton}><MoreHorizontal size={16} /></button>
                            </div>
                            <div className={styles.conversationContainer}>
                                <div className={styles.conversationHeader}>
                                    <h4>Conversation History</h4>
                                    <button 
                                        className={styles.viewToggle} 
                                        onClick={() => setCompactView(!compactView)}
                                    >
                                        {compactView ? 'Expand View' : 'Compact View'}
                                    </button>
                                </div>
                                {row.conversations && row.conversations.map((comment, index) => (
                                    <div key={index} className={`${styles.message} ${styles[comment.sender]} ${compactView ? styles.compact : ''}`}>
                                        <div className={styles.messageHeader}>
                                            <div className={styles.messageSender}>
                                                {comment.sender === 'customer' ? <UserIcon /> : <SupportIcon />}
                                                <span>{comment.sender === 'customer' ? 'Customer' : 'Agent'}</span>
                                            </div>
                                            <div className={styles.messageTime}>{formatDate(comment.timestamp)}</div>
                                        </div>
                                        <div className={styles.messageContent}>{comment.content}</div>
                                        {comment.attachments && (
                                            <div className={styles.attachments}>
                                                {comment.attachments.map((attachment, i) => (
                                                    <a key={i} href={attachment.url} className={styles.attachment}>
                                                        <Paperclip size={16} />
                                                        {attachment.name}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className={styles.replyContainer}>
                                <h4>Reply to Customer</h4>
                                <ReactQuill
                                    theme="snow"
                                    value={emailContent}
                                    onChange={setEmailContent}
                                    modules={{
                                        toolbar: [
                                            ['bold', 'italic', 'underline'],
                                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                            ['link'],
                                            ['clean']
                                        ],
                                    }}
                                />
                                <div className={styles.replyActions}>
                                    <button className={styles.attachButton}>
                                        <Paperclip size={16} />
                                        Attach Files
                                    </button>
                                    <button className={styles.sendReplyButton}>
                                        <Send size={16} />
                                        Send Reply
                                    </button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </td>
        </motion.tr>
    );

    const StatusBadge = ({ status }) => {
        const statusConfig = {
            open: { icon: AlertCircle, color: styles.statusOpen },
            solved: { icon: Check, color: styles.statusSolved },
            closed: { icon: Check, color: styles.statusClosed },
            hold: { icon: Clock, color: styles.statusHold }
        };

        const { icon: Icon, color } = statusConfig[status.toLowerCase()] || statusConfig.open;

        return (
            <span className={`${styles.statusBadge} ${color}`}>
                <Icon size={12} className={styles.statusIcon} />
                {status}
            </span>
        );
    };

    return (
        <div className={styles.tableContainer}>
            <table className={styles.dataTable}>
                <thead className={styles.stickyHeader}>
                    <tr>
                        <th></th>
                        <th onClick={() => handleSort('id')} className={styles.sortableHeader}>
                            Ticket ID {renderSortIcon('id')}
                        </th>
                        <th>Avatar</th>
                        <th onClick={() => handleSort('name')} className={styles.sortableHeader}>
                            Name {renderSortIcon('name')}
                        </th>
                        <th onClick={() => handleSort('ticketDescription')} className={styles.sortableHeader}>
                            Ticket Description {renderSortIcon('ticketDescription')}
                        </th>
                        <th onClick={() => handleSort('sentimentalAnalysis')} className={styles.sortableHeader}>
                            Sentiment {renderSortIcon('sentimentalAnalysis')}
                        </th>
                        <th>Tags</th>
                        <th onClick={() => handleSort('status')} className={styles.sortableHeader}>
                            Status {renderSortIcon('status')}
                        </th>
                        <th onClick={() => handleSort('updatedAt')} className={styles.sortableHeader}>
                            Last Updated {renderSortIcon('updatedAt')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <AnimatePresence>
                        {currentItems.length > 0 ? (
                            currentItems.map((row, index) => (
                                <React.Fragment key={row.id}>
                                    <motion.tr 
                                        onClick={() => handleExpandTicket(row.id)} 
                                        className={`${styles.dataRow} ${index % 2 === 0 ? styles.evenRow : styles.oddRow}`}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.3, delay: index * 0.05 }}
                                    >
                                        <td><input type="checkbox" onClick={(e) => e.stopPropagation()} /></td>
                                        <td>{row.id}</td>
                                        <td>
                                            <div className={styles.avatar} style={{ backgroundColor: getAvatarColor(row.avatar) }}>
                                                {row.avatar}
                                            </div>
                                        </td>
                                        <td>{row.name}</td>
                                        <td>{row.ticketDescription}</td>
                                        <td>{renderSentiment(row.sentimentalAnalysis)}</td>
                                        <td>
                                            <div className={styles.tagContainer}>
                                                {renderTags(row.tags)}
                                            </div>
                                        </td>
                                        <td>
                                            <StatusBadge status={row.status} />
                                        </td>
                                        <td>{formatDate(row.updatedAt)}</td>
                                    </motion.tr>
                                    {expandedRows[row.id] && renderExpandedRow(row)}
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="9">No tickets found</td>
                            </tr>
                        )}
                    </AnimatePresence>
                </tbody>
            </table>
            <div className={styles.pagination}>
                <button onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <ChevronLeft size={16} />
                    Previous
                </button>
                <span>{currentPage} of {totalPages}</span>
                <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    Next
                    <ChevronRight size={16} />
                </button>
            </div>
        </div>
    );
};

export default TicketTable;